import React from 'react';
import { Home } from './templates/Home/Home';
import './App.css';

const App = () => {
  return (
    <>
      <Home />
    </>
  );
};

export default App;
